import React from 'react';
import styled from 'styled-components';
import { Typography, Card, CardMedia, CardContent } from '@mui/material';
import cashier from '../assets/images/cashier.svg';
import kiosk from '../assets/images/kiosk.svg';
import web from '../assets/images/web.png';
import integrated from '../assets/images/integrated.png';

const GridContainer = styled.div`
  display: grid;
  gap: 16px;
  margin: 20px auto;
  padding: 16px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const products = [
  {
    title: 'Cashiering / Point of Sale System',
    description:
      "Led front-end development and authentication rewrite of Euna's Cashiering solution, bringing it from MVP to production for several clients. Enabled cashiers to perform account lookups and process payment transactions for constituents through a safe, secure, and easy-to-use interface.",
    link: 'https://thecitybase.com/products/cashiering',
    image: cashier
  },
  {
    title: 'Kiosk',
    description: "Significant developer of a secure, self-service kiosk web application for customers to pay all their bills in one place. Supports cash, card, and check payments. Hundreds of kiosks are stationed in major cities throughout the United States, serving tens of thousands of constituents.",
    image: kiosk
  },

  {
    title: 'Online Payments',
    description:
      'Significant developer of a mobile-friendly and accessible e-commerce web application with cart and checkout, used by tens of thousands of constituents across the United States to pay utility bills.',
    link: 'https://thecitybase.com/products/online-payments',
    image: web
  },
  {
    title: 'Revenue Management',
    description:
      'Significant developer of an integrated research, reporting, and reconciliation back office solution that comes standard with any CityBase product. Revenue Management is used for complete visibility into all payments across all channels and departments, in one place.',
    link: 'https://thecitybase.com/products/revenue-management',
    image: integrated
  }
];

const InfoCard = () => {
  return (
    <>
      <Typography
        variant="h6"
        sx={{ fontWeight: 'bold', marginBottom: '16px', fontSize: '1rem' }}
        textAlign="left"
      >
        <Typography>
          Throughout my current role at CityBase (now Euna Payments), I have
          worked across 3 different engineering teams on the following products
          (and more):
        </Typography>
        <GridContainer>
          {products.map((product, index) => (
            <Card
              key={index}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: 'space-between',
                gap: '16px',
                padding: '16px'
              }}
            >
              <CardMedia
                component="img"
                sx={{
                  width: { xs: '100%', md: 150 },
                  height: { xs: 'auto', md: 150 },
                  objectFit: 'cover',
                  borderRadius: '8px'
                }}
                image={product.image}
                alt={product.title}
              />
              <CardContent
                style={{ textAlign: 'left' }}
                sx={{ flex: 1, padding: { xs: '16px 0 0', md: '0 16px 0 0' } }}
              >
                <Typography
                  variant="h6"
                  component="a"
                  href={product.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    textDecoration: 'none',
                    color: 'primary.main',
                    fontWeight: 'bold',
                    fontSize: '1.5rem',
                    textAlign: 'left'
                  }}
                >
                  {product.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    marginTop: '8px',
                    color: 'text.secondary',
                    textAlign: 'left',
                    fontSize: '0.8rem'
                  }}
                >
                  {product.description}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </GridContainer>
      </Typography>
    </>
  );
};

export default InfoCard;
