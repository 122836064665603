import React from 'react';
import Scrollspy from 'react-scrollspy';
import Scroll from './Scroll';
import { Button } from '@mui/material';
import { navigate } from 'gatsby';
import { RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoggedOut } from '../store/slices/user';
const Nav = (props) => {
  const dispatch = useDispatch();
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center', // Ensure vertical alignment
        width: '100%',
        padding: '1em 0' // Add some padding for better spacing
      }}
    >
      <nav id="nav" className={props.sticky ? 'alt' : ''}>
        <div style={{ flex: 1 }}></div>
        <Scrollspy
          items={['intro', 'technical-skills', 'projects']}
          currentClassName="is-active"
          offset={-300}
          style={{
            display: 'flex',
            alignItems: 'center', // Align items vertically
            gap: '24px', // Space out links
            listStyle: 'none',
            margin: 0,
            padding: 0
          }}
        >
          <li>
            <Scroll type="id" element="intro">
              <a href="#">About</a>
            </Scroll>
          </li>
          <li>
            <Scroll type="id" element="projects">
              <a href="#">Recent Projects</a>
            </Scroll>
          </li>
          <li>
            <Scroll type="id" element="technical-skills">
              <a href="#">Technical Skills</a>
            </Scroll>
          </li>
        </Scrollspy>

        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => dispatch(setIsLoggedOut())}
            sx={(theme) => ({
              color: `${theme.palette.primary.main} !important`,
              backgroundColor: '#dddddd', // lighter grey closer to white
              borderColor: theme.palette.primary.main,
              '&:hover': {
                borderWidth: '2px'
              }
            })}
          >
            Logout
          </Button>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
